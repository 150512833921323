import React from "react"
import { CreateGuesser, EditGuesser, FieldGuesser, ListGuesser, ShowGuesser } from "@api-platform/admin"
import RouteField from '../fields/RouteFields'
import InputGuesser from '../InputGuesser'

export const ButtonListItemsList = props => (
  <ListGuesser {...props}>
          <FieldGuesser source="label"/>
          <RouteField source="route"/>
          <FieldGuesser source="highlightQuery"/>
          <FieldGuesser source="fragment"/>
          <FieldGuesser source="className"/>
  </ListGuesser>
)

export const ButtonListItemsShow = props => (
  <ShowGuesser {...props}>
          <FieldGuesser source="label" addLabel={true}/>
          <RouteField source="route" addLabel={true}/>
          <FieldGuesser source="highlightQuery" addLabel={true}/>
          <FieldGuesser source="fragment" addLabel={true}/>
          <FieldGuesser source="className" addLabel={true}/>
          <FieldGuesser source="locations" addLabel={true}/>
          <RouteField source="locations" addLabel={true} chipSource={"content"} label={"Location -> Component Groups"}/>
  </ShowGuesser>
)

export const ButtonListItemsEdit = props => (
  <EditGuesser {...props}>
          <InputGuesser source="label"/>
          <InputGuesser source="className"/>
          <InputGuesser source="route" optionText="route" searchKey="route"/>
          <InputGuesser source="fragment"/>
          <InputGuesser source="highlightQuery"/>
          <InputGuesser source="locations"/>
  </EditGuesser>
)

export const ButtonListItemsCreate = props => (
  <CreateGuesser {...props}>
          <InputGuesser source="label"/>
          <InputGuesser source="className"/>
          <InputGuesser source="route" optionText="route" searchKey="route"/>
          <InputGuesser source="fragment"/>
          <InputGuesser source="highlightQuery"/>
          <InputGuesser source="locations"/>
  </CreateGuesser>
)
