import React from 'react';
import Cookies from 'js-cookie';
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import { HydraAdmin, ResourceGuesser, hydraDataProvider as baseHydraDataProvider, fetchHydra as baseFetchHydra } from "@api-platform/admin";
import authProviderFn from './authProvider';
import { Redirect, Route } from "react-router-dom";
import { UserEdit, UserList, UserShow, UserCreate } from './resources/Users';
import { TextPagesCreate, TextPagesEdit, TextPagesList, TextPagesShow } from './resources/TextPages'
import { RoutesCreate, RoutesEdit, RoutesList } from './resources/Routes'
import { ButtonsPagesCreate, ButtonsPagesEdit, ButtonsPagesList, ButtonsPagesShow } from './resources/ButtonsPages'
import { ImageButtonsPagesCreate, ImageButtonsPagesEdit, ImageButtonsPagesList, ImageButtonsPagesShow } from './resources/ImageButtonsPages'
import { StaticPagesCreate, StaticPagesEdit, StaticPagesList, StaticPagesShow } from './resources/StaticPages'
import { ButtonListNavigationsCreate, ButtonListNavigationsEdit, ButtonListNavigationsList, ButtonListNavigationsShow } from './resources/ButtonListNavigations'
import { ButtonListItemsCreate, ButtonListItemsEdit, ButtonListItemsList, ButtonListItemsShow } from './resources/ButtonListItems'
import { ComponentGroupsCreate, ComponentGroupsEdit, ComponentGroupsList, ComponentGroupsShow } from './resources/ComponentGroups'
import { LargePathButtonsCreate, LargePathButtonsEdit, LargePathButtonsList, LargePathButtonsShow } from './resources/LargePathButtons'
import { NextBackNavsCreate, NextBackNavsEdit, NextBackNavsList, NextBackNavsShow } from './resources/NextBackNavs'

const entrypoint
  = process.env.REACT_APP_API_ENTRYPOINT;
const cookie_domain = process.env.REACT_APP_COOKIE_DOMAIN

const authProvider = authProviderFn(cookie_domain, entrypoint)

const fetchHeaders = {'Authorization': `Bearer ${Cookies.get('TKN')}`};
const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
  ...options,
  headers: new Headers(fetchHeaders),
});
const apiDocumentationParser = (entrypoint) => parseHydraDocumentation(entrypoint, { headers: new Headers(fetchHeaders) })
  .then(
    ({ api }) => {
      api.resources = api.resources.filter(({ name }) => {
        return ['users', 'text_pages', 'buttons_pages', 'button_list_navigations', 'button_list_items', 'component_groups', 'component_locations', 'routes', 'static_pages', 'image_buttons_pages', 'large_path_buttons', 'next_back_navs', 'collections', 'team_members'].indexOf(name) !== -1
      });
      return { api };
    },
    (result) => {
      switch (result.status) {
        case 401:
          return Promise.resolve({
            api: result.api,
            customRoutes: [
              <Route path="/" render={() => {
                return Cookies.get('TKN') ? window.location.reload() : <Redirect to="/login" />
              }} />
            ],
          });

        default:
          return Promise.reject(result);
      }
    },
  );

const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser);

export default props => (
  <HydraAdmin
    dataProvider={ dataProvider }
    authProvider={ authProvider }
    entrypoint={ entrypoint }
  >
    <ResourceGuesser name="users" list={UserList} edit={UserEdit} show={UserShow} create={UserCreate} />
    <ResourceGuesser name="text_pages" list={TextPagesList} show={TextPagesShow} edit={TextPagesEdit} create={TextPagesCreate} />
    <ResourceGuesser name="buttons_pages" list={ButtonsPagesList} show={ButtonsPagesShow} edit={ButtonsPagesEdit} create={ButtonsPagesCreate} />
    <ResourceGuesser name="image_buttons_pages" list={ImageButtonsPagesList} show={ImageButtonsPagesShow} edit={ImageButtonsPagesEdit} create={ImageButtonsPagesCreate} />
    <ResourceGuesser name="static_pages" list={StaticPagesList} show={StaticPagesShow} edit={StaticPagesEdit} create={StaticPagesCreate} />
    <ResourceGuesser name="button_list_navigations" list={ButtonListNavigationsList} show={ButtonListNavigationsShow} edit={ButtonListNavigationsEdit} create={ButtonListNavigationsCreate} />
    <ResourceGuesser name="component_groups" list={ComponentGroupsList} show={ComponentGroupsShow} edit={ComponentGroupsEdit} create={ComponentGroupsCreate} />
    <ResourceGuesser name="component_locations" />
    <ResourceGuesser name="button_list_items" list={ButtonListItemsList} show={ButtonListItemsShow} edit={ButtonListItemsEdit} create={ButtonListItemsCreate} />
    <ResourceGuesser name="large_path_buttons" list={LargePathButtonsList} show={LargePathButtonsShow} edit={LargePathButtonsEdit} create={LargePathButtonsCreate} />
    <ResourceGuesser name="routes" list={RoutesList} edit={RoutesEdit} create={RoutesCreate} />
    <ResourceGuesser name="next_back_navs" list={NextBackNavsList} show={NextBackNavsShow} edit={NextBackNavsEdit} create={NextBackNavsCreate} />
    <ResourceGuesser name="collections" />
    <ResourceGuesser name="team_members" />
  </HydraAdmin>
);
