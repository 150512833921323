import React from "react"
import { RichTextField } from 'react-admin'
import { CreateGuesser, EditGuesser, FieldGuesser, ListGuesser, ShowGuesser } from "@api-platform/admin"
import RouteField from '../fields/RouteFields'
import InputGuesser from '../InputGuesser'

export const LargePathButtonsList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source="label"/>
    <RouteField source="route"/>
    <FieldGuesser source="fragment"/>
    <FieldGuesser source="className"/>
  </ListGuesser>
)

export const LargePathButtonsShow = props => (
  <ShowGuesser {...props}>
    <FieldGuesser source="label" addLabel={true}/>
    <RouteField source="route" addLabel={true}/>
    <FieldGuesser source="fragment" addLabel={true}/>
    <FieldGuesser source="className" addLabel={true}/>
    <FieldGuesser source="locations" addLabel={true}/>
    <RouteField source="locations" addLabel={true} chipSource={"content"} label={"Location -> Component Groups"}/>
  </ShowGuesser>
)

export const LargePathButtonsEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source="label"/>
    <RichTextField source={"description"}/>
    <InputGuesser source="className"/>
    <InputGuesser source="route" optionText="route" searchKey="route"/>
    <InputGuesser source="fragment"/>
    <InputGuesser source="locations"/>
  </EditGuesser>
)

export const LargePathButtonsCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source="label"/>
    <RichTextField source={"description"}/>
    <InputGuesser source="className"/>
    <InputGuesser source="route" optionText="route" searchKey="route"/>
    <InputGuesser source="fragment"/>
    <InputGuesser source="locations"/>
  </CreateGuesser>
)
