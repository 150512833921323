import React from "react"
import { CreateGuesser, EditGuesser, FieldGuesser, ListGuesser, ShowGuesser } from "@api-platform/admin"
import InputGuesser from '../InputGuesser'

export const ButtonListNavigationsList = props => (
  <ListGuesser {...props}>
      <FieldGuesser source={"title"} addLabel={true}/>
      <FieldGuesser source={"description"} addLabel={true}/>
      <FieldGuesser source={"className"} addLabel={true}/>
      <FieldGuesser source={"componentName"} addLabel={true}/>
  </ListGuesser>
)

export const ButtonListNavigationsShow = props => (
  <ShowGuesser {...props}>
      <FieldGuesser source={"title"} addLabel={true}/>
      <FieldGuesser source={"description"} addLabel={true}/>
      <FieldGuesser source={"className"} addLabel={true}/>
      <FieldGuesser source={"componentName"} addLabel={true}/>
      <FieldGuesser source={"componentGroups"} addLabel={true}/>
  </ShowGuesser>
)

export const ButtonListNavigationsEdit = props => (
  <EditGuesser {...props}>
      <InputGuesser source="title"/>
      <InputGuesser source="description"/>
      <InputGuesser source="subtitle"/>
      <InputGuesser source="className"/>
      <InputGuesser source="componentName"/>
      <InputGuesser source="componentGroups"/>
      <InputGuesser source="columns"/>
  </EditGuesser>
)

export const ButtonListNavigationsCreate = props => (
  <CreateGuesser {...props}>
      <InputGuesser source="title"/>
      <InputGuesser source="description"/>
      <InputGuesser source="subtitle"/>
      <InputGuesser source="className"/>
      <InputGuesser source="componentName"/>
      <InputGuesser source="componentGroups"/>
      <InputGuesser source="columns"/>
  </CreateGuesser>
)
