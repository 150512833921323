import React from "react"
import {
        ListGuesser,
        ShowGuesser,
        FieldGuesser,
        EditGuesser,
        CreateGuesser
} from "@api-platform/admin"
import InputGuesser from '../InputGuesser'

export const ComponentGroupsList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source={"component"} />
    <FieldGuesser source={"parent"} />
  </ListGuesser>
);

export const ComponentGroupsShow = props => (
  <ShowGuesser {...props}>
    <FieldGuesser source={"componentLocations"} addLabel={true} />
    <FieldGuesser source={"component"} addLabel={true} />
    <FieldGuesser source={"parent"} addLabel={true} />
  </ShowGuesser>
);

export const ComponentGroupsEdit = props => (
  <EditGuesser {...props}>
          <InputGuesser source="componentLocations" />
  </EditGuesser>
);

export const ComponentGroupsCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source="componentLocations" />
  </CreateGuesser>
);
