import React from "react"
import { RichTextField, required } from 'react-admin'
import {
  ListGuesser,
  ShowGuesser,
  FieldGuesser,
  EditGuesser,
  CreateGuesser
} from "@api-platform/admin"
import RoutesField from '../fields/RouteFields'
import InputGuesser from '../InputGuesser'

export const ButtonsPagesList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source={"title"} />
    <RoutesField source={"routes"} addLabel={true} />
  </ListGuesser>
);

export const ButtonsPagesShow = props => (
  <ShowGuesser {...props}>
    <FieldGuesser source={"published"} addLabel={true} />
    <RoutesField source={"routes"} addLabel={true} />
    <FieldGuesser source={"preTitle"} addLabel={true} />
    <FieldGuesser source={"title"} addLabel={true} />
    <RichTextField source={"content"} addLabel={true} />
    <FieldGuesser source={"buttonsNav"} addLabel={true} />
    <RoutesField source={"backRoute"} addLabel={true} />
    <RoutesField source={"nextRoute"} addLabel={true} />
  </ShowGuesser>
);

export const ButtonsPagesEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source="published" />
    <InputGuesser source="publishedDate" validate={[required()]} />
    <InputGuesser source="routes" optionText="route" />
    <InputGuesser source="preTitle" />
    <InputGuesser source="title" />
    <InputGuesser source="iconClass" />
    <InputGuesser source="iconAlt" />
    <InputGuesser source="content" />
    <InputGuesser source="buttonsNav" />
    <InputGuesser source={"useBrowserBackByDefault"} />
    <InputGuesser source="backRoute" optionText="route" addLabel={true} searchKey="route" />
    <InputGuesser source="nextRoute" optionText="route" addLabel={true} searchKey="route" />
    <InputGuesser source="backRouteQuery" />
    <InputGuesser source="nextRouteQuery" />
  </EditGuesser>
);

export const ButtonsPagesCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source="published" />
    <InputGuesser source="publishedDate" validate={[required()]} />
    <InputGuesser source="routes" optionText="route" />
    <InputGuesser source="preTitle" />
    <InputGuesser source="title" />
    <InputGuesser source="iconClass" />
    <InputGuesser source="iconAlt" />
    <InputGuesser source="content" />
    <InputGuesser source="buttonsNav" />
    <InputGuesser source={"useBrowserBackByDefault"} />
    <InputGuesser source="backRoute" optionText="route" addLabel={true} searchKey="route" />
    <InputGuesser source="nextRoute" optionText="route" addLabel={true} searchKey="route" />
    <InputGuesser source="backRouteQuery" />
    <InputGuesser source="nextRouteQuery" />
  </CreateGuesser>
);
