import React from 'react'
import _propTypes from 'prop-types'
import _Introspecter from '@api-platform/admin/lib/InputGuesser'
var _reactAdmin = require("react-admin")

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

const isFieldSortable = (field, schema) => {
  return schema.parameters.filter(parameter => parameter.variable === field.name).length > 0 && schema.parameters.filter(parameter => parameter.variable === `order[${field.name}]`).length > 0;
};

const renderReferenceArrayField = (referenceField, schemaAnalyzer, props) => {
  return React.createElement(_reactAdmin.ReferenceArrayField, _extends({
    reference: referenceField.name
  }, props), React.createElement(_reactAdmin.SingleFieldList, null, React.createElement(_reactAdmin.ChipField, {
    source: props.chipSource,
    key: props.chipSource
  })));
};

const renderField = (field, schemaAnalyzer, props) => {
  if (!props.chipSource) {
    props.chipSource = 'route'
  }

  if (null !== field.reference) {
    if (1 === field.maxCardinality) {
      return React.createElement(_reactAdmin.ReferenceField, _extends({
        reference: field.reference.name
      }, props, {
        allowEmpty: true
      }), React.createElement(_reactAdmin.ChipField, {
        source: props.chipSource
      }));
    }

    return renderReferenceArrayField(field.reference, schemaAnalyzer, props);
  }

  if (null !== field.embedded && 1 !== field.maxCardinality) {
    return renderReferenceArrayField(field.embedded, schemaAnalyzer, props);
  }
};

const IntrospectedFieldGuesser = ({
  fields,
  readableFields,
  writableFields,
  schema,
  schemaAnalyzer,
  ...props
}) => {
  const field = fields.find(f => f.name === props.source);

  if (!field) {
    console.error(`Field "${props.source}" not present inside API description for the resource "${props.resource}"`);
    return React.createElement(React.Fragment, null);
  }

  return renderField(field, schemaAnalyzer, {
    sortable: isFieldSortable(field, schema),
    ...props
  });
};

export const RoutesField = props => React.createElement(_Introspecter, _extends({
  component: IntrospectedFieldGuesser,
  includeDeprecated: true
}, props));

RoutesField.propTypes = {
  source: _propTypes.string.isRequired
};

export default RoutesField
