import React from "react"

import {
    ListGuesser,
    ShowGuesser,
    FieldGuesser,
    EditGuesser,
    CreateGuesser
} from "@api-platform/admin"
import RoutesField from '../fields/RouteFields'
import InputGuesser from '../InputGuesser'

export const NextBackNavsList = props => (
  <ListGuesser {...props}>
      <RoutesField source={"backRoute"} />
      <RoutesField source={"nextRoute"} />
      <FieldGuesser source={"useBrowserBackByDefault"} />
      <FieldGuesser source={"backRouteQuery"} addLabel={true} />
      <FieldGuesser source={"nextRouteQuery"} addLabel={true} />
  </ListGuesser>
);

export const NextBackNavsShow = props => (
  <ShowGuesser {...props}>
      <RoutesField source={"backRoute"} addLabel={true} />
      <RoutesField source={"nextRoute"} addLabel={true} />
      <FieldGuesser source={"useBrowserBackByDefault"} />
      <FieldGuesser source={"backRouteQuery"} addLabel={true} />
      <FieldGuesser source={"nextRouteQuery"} addLabel={true} />
  </ShowGuesser>
);

export const NextBackNavsEdit = props => (
  <EditGuesser {...props}>
      <InputGuesser source={"useBrowserBackByDefault"} />
      <InputGuesser source="backRoute" optionText="route" searchKey="route" />
      <InputGuesser source="nextRoute" optionText="route" searchKey="route" />
      <InputGuesser source={"backRouteQuery"} />
      <InputGuesser source={"nextRouteQuery"} />
  </EditGuesser>
);

export const NextBackNavsCreate = props => (
  <CreateGuesser {...props}>
      <InputGuesser source={"useBrowserBackByDefault"} />
      <InputGuesser source="backRoute" optionText="route" searchKey="route" />
      <InputGuesser source="nextRoute" optionText="route" searchKey="route" />
      <InputGuesser source={"backRouteQuery"} />
      <InputGuesser source={"nextRouteQuery"} />
  </CreateGuesser>
);
