import React from "react";
import {
  ListGuesser,
  FieldGuesser,
  EditGuesser,
  CreateGuesser
} from "@api-platform/admin";
import InputGuesser from '../InputGuesser'

export const RoutesList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source={"route"} />
  </ListGuesser>
);

export const RoutesEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source="redirectedFrom" optionText="route" />
    <InputGuesser source="name" />
    <InputGuesser source="route" />
    <InputGuesser source="redirectRoute" optionText="route" />
  </EditGuesser>
);

export const RoutesCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source="redirectedFrom" optionText="route" />
    <InputGuesser source="name" />
    <InputGuesser source="route" />
    <InputGuesser source="redirectRoute" optionText="route" />
  </CreateGuesser>
);
