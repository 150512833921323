import React from "react"
import {
  ListGuesser,
  ShowGuesser,
  FieldGuesser,
  EditGuesser,
  CreateGuesser
} from "@api-platform/admin"
import { RoutesField } from '../fields/RouteFields'
import InputGuesser from '../InputGuesser'

export const StaticPagesList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source={"title"} />
    <FieldGuesser source={"metaDescription"} />
    <RoutesField source={"routes"} addLabel={true} />
  </ListGuesser>
);

export const StaticPagesShow = props => (
  <ShowGuesser {...props}>
    <RoutesField source={"routes"} addLabel={true} />
    <FieldGuesser source={"title"} addLabel={true} />
    <FieldGuesser source={"metaDescription"} addLabel={true} />
    <FieldGuesser source={"componentLocations"} addLabel={true} />
  </ShowGuesser>
);

export const StaticPagesEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source="routes" optionText="route" />
    <InputGuesser source="title" />
    <InputGuesser source="metaDescription" />
    <InputGuesser source="componentLocations" />
  </EditGuesser>
);

export const StaticPagesCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source="routes" optionText="route" />
    <InputGuesser source="title" />
    <InputGuesser source="metaDescription" />
    <InputGuesser source="componentLocations" />
  </CreateGuesser>
);
