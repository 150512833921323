import React from "react"
import { RichTextField, required } from 'react-admin'
import {
  ListGuesser,
  ShowGuesser,
  FieldGuesser,
  EditGuesser,
  CreateGuesser
} from "@api-platform/admin"
import { RoutesField } from '../fields/RouteFields'
import InputGuesser from '../InputGuesser'

export const ImageButtonsPagesList = props => (
  <ListGuesser {...props}>
    <FieldGuesser source={"title"} />
    <RoutesField source={"routes"} addLabel={true} />
  </ListGuesser>
);

export const ImageButtonsPagesShow = props => (
  <ShowGuesser {...props}>
    <FieldGuesser source={"published"} addLabel={true} />
    <RoutesField source={"routes"} addLabel={true} />
    <FieldGuesser source={"title"} addLabel={true} />
    <FieldGuesser source={"subtitle"} addLabel={true} />
    <RichTextField source={"content"} addLabel={true} />
    <FieldGuesser source={"buttonsNav"} addLabel={true} />
  </ShowGuesser>
);

export const ImageButtonsPagesEdit = props => (
  <EditGuesser {...props}>
    <InputGuesser source="published" />
    <InputGuesser source="publishedDate" validate={[required()]} />
    <InputGuesser source="routes" optionText="route" />
    <InputGuesser source="title" />
    <InputGuesser source="subtitle" />
    <InputGuesser source="content" />
    <InputGuesser source="imageCaption" />
    <InputGuesser source="buttonsNav" />
    <InputGuesser source={"useBrowserBackByDefault"} />
    <InputGuesser source="backRoute" optionText="route" searchKey="route" />
    <InputGuesser source="nextRoute" optionText="route" searchKey="route" />
    <InputGuesser source="backRouteQuery" />
    <InputGuesser source="nextRouteQuery" />
  </EditGuesser>
);

export const ImageButtonsPagesCreate = props => (
  <CreateGuesser {...props}>
    <InputGuesser source="published" />
    <InputGuesser source="publishedDate" validate={[required()]} />
    <InputGuesser source="routes" optionText="route" />
    <InputGuesser source="title" />
    <InputGuesser source="subtitle" />
    <InputGuesser source="content" />
    <InputGuesser source="imageCaption" />
    <InputGuesser source="buttonsNav" />
    <InputGuesser source={"useBrowserBackByDefault"} />
    <InputGuesser source="backRoute" optionText="route" searchKey="route" />
    <InputGuesser source="nextRoute" optionText="route" searchKey="route" />
    <InputGuesser source="backRouteQuery" />
    <InputGuesser source="nextRouteQuery" />
  </CreateGuesser>
);
