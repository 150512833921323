import React from "react"
import { RichTextField, required } from 'react-admin'
import {
      ListGuesser,
      ShowGuesser,
      FieldGuesser,
      EditGuesser,
      CreateGuesser
} from "@api-platform/admin"
import RoutesField from '../fields/RouteFields'
import InputGuesser from '../InputGuesser'

export const TextPagesList = props => (
  <ListGuesser {...props}>
        <FieldGuesser source={"title"} />
        <FieldGuesser source={"subtitle"} />
        <RoutesField source={"routes"} />
        <RoutesField source={"backRoute"} />
        <RoutesField source={"nextRoute"} />
  </ListGuesser>
);

export const TextPagesShow = props => (
  <ShowGuesser {...props}>
        <FieldGuesser source={"published"} addLabel={true} />
        <RoutesField source={"routes"} addLabel={true} />
        <FieldGuesser source={"title"} addLabel={true} />
        <FieldGuesser source={"subtitle"} addLabel={true} />
        <RichTextField source={"content"} addLabel={true} />
        <FieldGuesser source={"imageCaption"} addLabel={true} />
        <FieldGuesser source={"buttonText"} addLabel={true} />
        <FieldGuesser source={"buttonClass"} addLabel={true} />
        <FieldGuesser source={"buttonCaption"} addLabel={true} />
        <FieldGuesser source={"buttonRoute"} addLabel={true} />
        <RichTextField source={"modalContent"} addLabel={true} />
        <FieldGuesser source={"modalTitle"} addLabel={true} />
        <RoutesField source={"backRoute"} addLabel={true} />
        <RoutesField source={"nextRoute"} addLabel={true} />
        <FieldGuesser source={"backRouteQuery"} addLabel={true} />
        <FieldGuesser source={"nextRouteQuery"} addLabel={true} />
        <FieldGuesser source={"showPrint"} addLabel={true} />
        <FieldGuesser source={"mediaUrl"} addLabel={true} />
        <FieldGuesser source={"buttonHideFirstLogin"} addLabel={true} />
  </ShowGuesser>
);

export const TextPagesEdit = props => (
  <EditGuesser {...props}>
        <InputGuesser source="published" />
        <InputGuesser source="publishedDate" validate={[required()]} />
        <InputGuesser source="routes" optionText="route" />
        <InputGuesser source="title" />
        <InputGuesser source="subtitle" />
        <InputGuesser source="content" />
        <InputGuesser source="centeredText" />
        <InputGuesser source={"imageCaption"} />
        <InputGuesser source={"buttonText"} />
        <InputGuesser source={"buttonClass"} />
        <InputGuesser source={"buttonCaption"} />
        <InputGuesser source={"buttonUrl"} />
        <InputGuesser source={"buttonRoute"} optionText="route" searchKey="route" />
        <InputGuesser source={"modalContent"} />
        <InputGuesser source={"modalTitle"} />
        <InputGuesser source={"useBrowserBackByDefault"} />
        <InputGuesser source="backRoute" optionText="route" searchKey="route" />
        <InputGuesser source="nextRoute" optionText="route" searchKey="route" />
        <InputGuesser source={"backRouteQuery"} />
        <InputGuesser source={"nextRouteQuery"} />
        <InputGuesser source={"showPrint"} />
        <InputGuesser source={"mediaUrl"} />
        <InputGuesser source={"buttonHideFirstLogin"} />
  </EditGuesser>
);

export const TextPagesCreate = props => (
  <CreateGuesser {...props}>
        <InputGuesser source="published" />
        <InputGuesser source="publishedDate" validate={[required()]} />
        <InputGuesser source="routes" optionText="route" />
        <InputGuesser source="title" />
        <InputGuesser source="subtitle" />
        <InputGuesser source="content" />
        <InputGuesser source="centeredText" />
        <InputGuesser source={"imageCaption"} />
        <InputGuesser source={"buttonText"} />
        <InputGuesser source={"buttonClass"} />
        <InputGuesser source={"buttonCaption"} />
        <InputGuesser source={"buttonUrl"} />
        <InputGuesser source={"buttonRoute"} optionText="route" searchKey="route" />
        <InputGuesser source={"modalContent"} />
        <InputGuesser source={"modalTitle"} />
        <InputGuesser source={"useBrowserBackByDefault"} />
        <InputGuesser source="backRoute" optionText="route" searchKey="route" />
        <InputGuesser source="nextRoute" optionText="route" searchKey="route" />
        <InputGuesser source={"backRouteQuery"} />
        <InputGuesser source={"nextRouteQuery"} />
        <InputGuesser source={"showPrint"} />
        <InputGuesser source={"mediaUrl"} />
        <InputGuesser source={"buttonHideFirstLogin"} />
  </CreateGuesser>
);
